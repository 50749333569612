@import url("https://use.typekit.net/plv7vax.css");

@import './_vars.scss';

html, body {
  background:  $pink !important;
}


.cravesunset {
  // background-color: $white;
  background-color: #f7f7f7;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23eaeaea' fill-opacity='0.4'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  .page {
    min-height: 100vh;
  }
  .page-header {
    max-width: $max-site-width;
    margin: 1em auto;
    padding-left: 0.2em;
    padding-bottom: 0;
    h1 {
      text-transform: uppercase;
      font-size: 1.5em;
      color: $red;
    }
    border-bottom: 2px solid $red;
    display: flex;
    align-items: center;
    .text {
      flex: 1;
    }
    .logo-wrapper {
      width: 100px;
      height: 98px;
      // justify-self: flex-end;
      margin: 0;
      align-self: flex-end;
      svg {
        // border-bottom: 2px solid black;
        display: inline-block;
        margin: 0;
        padding: 0;
      }
    }
  }
  .product-link {
    background: $red;
    border: none;
    color: $white;
    display: flex;
    font-weight: 600;
    align-items: center;
    .plus {
      font-weight: 200;
      position: relative;
      top: -0.08em;
      font-size: 2em;
      line-height: 0.3em;
    }
    &:visited {
      color: $white;
    }
  }
  font-family: $signatureFont;
  background-color: $background-color;
  svg.nav-bar-logo {
    display: block;
    position: relative;
    // height: 35px;
    // margin-top: -0.4em;
    // bottom: -0.6em;
    // padding-top: 0.23em;
    // padding-left: 0.3em;
    // padding-bottom: 0.023em;
  }
  .nav-bar-logo {

    // background: blue;
    height: 55px;
    padding-bottom: 0;
    display: block;
    // margin-bottom: -0.2em;

    // margin-left: 0.3em;
  }
  .tool-bar {
    padding-top: 0.35em;
    // display: none;

  }

  .cart.minimized {
    // background: rgba(237,237,237, 0.8);
    // display: none;
    padding-left: 0;
    padding-top: 0;
    background-color: $white;
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }

  a.checkout-button.button {
    background: $pink;
    text-transform: capitalize;

    color: $white;
    font-weight: 600;
  }

  .category__header {
    text-align: left;
    max-width: 840px;
    margin: 0 auto;

    border-bottom: 1.5px solid black;
    // padding: 0 0.5em;
    /* background: rgba(237,237,237, 0.4); */

  }
  .category-name {
    margin-left: 0em;
    padding-left: 0.2em;
  }
  .category__description {
    text-align: left;
    margin-left: 0em;
    padding-left: 0.2em;
    padding-bottom: 0.5em;
    max-width: none;
  }

  .category-name .text {
    font-family: $deco;
    font-weight: 600;
    // text-decoration: underline;
    // text-decoration-thickness: 2px;
    font-size: 1.5em;
    padding: 0;
    background: none;
    color: black;

  }
  .category-name .text::before {
    // content: "☙"
  }
  .menu-category-list-view {
    .photos.grid {

      justify-content: space-between;
    }
    .menu-item {
      background: none;
      // align-items: center;
      .photo-container {
        width: 300px;
      }
      .food-name {

        font-weight: 600;
        line-height: 1.5em;

      }
      @media (min-width: 550px) {
        width: 49%
      }

    }

    .content {
      flex: auto;
    }


    .price {
      // color: $red;
      // &::before {
      //   content: "..."
      // }
    }
    a.product-link {
      // align-self: center;
      margin-right: 0.9em;
      font-size: 0.8em;

    }
  }



  .price-summary {
    padding-bottom: 5em;
  }
  //
  //
  .pages-nav {
    font-family: $deco;
    background: $black;
    font-weight: 400;
    font-size: 0.8em;

    ul {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }

  a.page-link {
    $borderWidth: 3px;
    // padding-top: 0.5em;
    $paddingY: 0.35em;
    text-decoration: none;
    color: $white;
    display: block;
    padding: $paddingY 0.3em;
    padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
    // box-sizing: border-box;
    border-bottom: $borderWidth solid rgba(100,100,100,0);
    &:hover, &.active {
      border-bottom: $borderWidth solid $white;
    }
  }

  .page-content {
    max-width: 600px;
    margin: 1em auto;
    h1 {
      font-weight: 600;
      font-size: 1.5em;
      font-family: $deco;
    }
  }

  .full-menu-link-wrapper {
    padding: 1em 0;
    text-align: center;
    a.button {
      background: $pink;
      color: $white;
    }
  }

  .mag-glass-svg {
    display: none !important;
  }


  .footer {
    font-family: $signatureFont;
    margin-top: 2em;
    // border-top: 4px solid $pink;

    // background: $white;
    // color: black;
    a.phone-link {
      color: $pink;
    }
  }
}
