$sigFont: atten-round-new, sans-serif;
$signatureFont: $sigFont;
$sigFontWide: atten-round-new, sans-serif;
$deco: atten-round-new, sans-serif;
$white: #fff;
$black: #000;
// $red: #aa0d00;
$yellow: #F5C84B;
$red: rgb(186, 20, 20);
$pink:  $red;
$darkRed: #940b0b;
$orange: #f1751b;
$darkOrange: #984911;
// $background-color: #fffaec;
$background-color: #fff;
$purpleRadio: radial-gradient(circle, rgba(106,0,161,0.8925945378151261) 25%, rgba(156,4,95,0.7301295518207283) 100%);

$max-site-width: 840px;
$brown: #743d2c;
