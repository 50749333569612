.form-wrapper-contact {
  max-width: 900px;
  margin: 2em auto;

  form {
    border: 2px solid black;
    border-radius: 1em;
    margin-bottom: 1em;
    p {
      margin: 1em 0;
    }
    h2 {
      font-size: 1.5em;
      margin: 1em 0;
    }
    .label-text {
      min-width: 4em;
      display: inline-block;
    }
    padding: 1em;
    color: inherit;

    p {
      // font-family: Helvetica;
    }
    button {
      background: #0a4e61;
      color: white;
      width: 5em;
    }
  }
}
